import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import Container from "../../common/Container";
import {
  HeaderSection,
  LogoContainer,
  
} from "./styles";
import { getScroll } from "../../common/utils/getWindow";
import { SvgIcon } from "../../common/SvgIcon";
import { LanguageSwitchContainer, LanguageSwitch } from "../Footer/styles";
import i18n from "i18next";
import { useState, useEffect } from "react";

const Header = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = (event: any) => {
    const offsetFromTop = getScroll(event.target, true);
   
    if ( offsetFromTop > 70) {
      setShowScroll(true);
    } else if (offsetFromTop <= 70) {
      setShowScroll(false);
    }
    console.log(showScroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const [visible, setVisibility] = useState(false);

  // const showDrawer = () => {
  //   setVisibility(!visible);
  // };

  // const onClose = () => {
  //   setVisibility(!visible);
  // };

  // const MenuItem = () => {
  //   const scrollTo = (id: string) => {
  //     const element = document.getElementById(id) as HTMLDivElement;
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //     setVisibility(false);
  //   };
  //   return (
  //     <>
  //       <CustomNavLinkSmall onClick={() => scrollTo("about")}>
  //         <Span>{t("About")}</Span>
  //       </CustomNavLinkSmall>
  //       <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
  //         <Span>{t("Mission")}</Span>
  //       </CustomNavLinkSmall>
  //       <CustomNavLinkSmall onClick={() => scrollTo("product")}>
  //         <Span>{t("Product")}</Span>
  //       </CustomNavLinkSmall>
  //       <CustomNavLinkSmall
  //         style={{ width: "180px" }}
  //         onClick={() => scrollTo("contact")}
  //       >
  //         <Span>
  //           <Button>{t("Contact")}</Button>
  //         </Span>
  //       </CustomNavLinkSmall>
  //     </>
  //   );
  // };

  return (
    <HeaderSection show={showScroll}>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img src="img/icons/logo.png" alt="logo" width="101px" height="58px" />
          </LogoContainer>
          <Col lg={6} md={6} sm={12} xs={12}>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("ar")}>
                  <SvgIcon
                    src="saudi.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
        </Row>
      
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
