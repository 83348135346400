import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import myRoutes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  var style = {
    
    div: {
      "backgroundColor": "#e5e5f7",
opacity: 0.8,
boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
"backgroundImage": "radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px)",
"backgroundSize":" 10px 10px",
    }as React.CSSProperties, 


    
  };
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <div style={style.div}>

      <Routes>
        {myRoutes.map((routeItem) => {
          return (
            <Route
            key={routeItem.component}
            
            path={routeItem.path}
            Component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
            );
          })}
      </Routes>
          </div>
      <Footer />
    </Suspense>
  );
};

export default Router;
