const myRoutes = [
  {
    path: "/", 
    exact: true,
    component: "Home",
  },
  {
    path: "*", 
    exact: true,
    component: "P404",
  },
  {
    path: "/p",
    exact: true,
    component: "Product",
  },
  {
    path:  "/s/:id",
    exact: true,
    component: "Seller",
  },
];

export default myRoutes;
